


.body {
    margin: 0px;
    padding: 0px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
   
  }
  
  
  .sec-add {
    margin-top: 6rem;
  }
  .btn{
    background-color: #fe9b01;
    border-radius: 20px;
    padding: 10px 20px;
  }
  
  .hero {
    /* background-image: url(/images/homew.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
    width: auto;
    overflow: hidden;
    background-color: rgba(215, 239, 38, 0.247);
    
  }
  
  .hero .hero-txt h1 {
   
    font-size: 3em;
  }
  
  .home-card {
    margin-top: -3rem;
    border-color: yellow;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  .abt-img {
    border-radius: 6%;
  }
  
  .abt-img2 {
    border-radius: 1%;
  }
  .about-txt h2 {
    text-align: start;
  }
  .about-us p{
    text-align: justify;
  }
  
  footer {
    background-color: rgba(255, 255, 255, 0.829);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    
  }
  
  .card-all {
    border-radius: 8px;
    border-color: yellow;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  .header-overlay {
    /* background-image: url(../images/homew.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh;
    width: 100%;
    


   
  }
  
  .header-overlay .overlay-txt {
    background-color: rgba(247, 250, 226, 0.247);
    color: white;
    height: 100%;
    width: 100%;
    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

            
  }
  
  .cta {
    /* 
    +background-image: url(../images/home.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh;
    width: 100%;
  }
  
  .cta .overlay-txt {
    background-color: rgba(31, 102, 124, 0.247);
    color: white;
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  @media screen and (max-width: 900px) {
    .home-card {
      margin-bottom: 5rem;
    }
    .about-txt {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
    .about-txt h2 {
      text-align: start;
    }
    #img3 {
      display: none;
    }
  }
  
  @media screen and (max-width: 500px) {
    .home-card {
      margin-bottom: 5rem;
    }
    .about-txt {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
   
    #img3 {
      display: none;
    }
  }
  .navbar-toggler:focus{
    outline: none;
    box-shadow: none;
  }

  .new-pic{
max-width: 400px;
  }